ccs.SkinNode = (function () {

    var Node = cc.Node;

    var proto = {};

    var SkinNode = Node.extend(proto);

    SkinNode.create = function () {
    };

    return SkinNode;

})();
